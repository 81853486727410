<template>
  <div class="d-flex justify-center">
    <div class="bruisweek">
      <h1>Welkom bij De Oase!</h1>
      <div class="mb-6 intro-text">
        <p>
          Wat leuk dat je mee hebt gedaan aan de mini-games van de Oase!
        </p>
        <p>
          Om je op de hoogte te houden van jouw positie op het scorebord en of je in aanmerking komt voor de prijzen willen we je vragen hier jouw gegevens in te vullen. Zo weten we je sowieso te vinden. 😁
        </p>
      </div>
      <v-form ref="form" v-model="isFormValid" @submit.prevent="submitForm" validate-on="input">
        <v-row>
          <v-col cols="8">
            <v-text-field label="Voornaam" v-model="person.name" :rules="nameRules" required></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Tussenvoegsel" v-model="person.preposition"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Achternaam" v-model="person.surname" :rules="nameRules" required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="E-mail" v-model="person.email" type="email" :rules="emailRules" required></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col>
                <div class="switch-wrapper">
                    <v-switch v-model="person.gave_permission" class="switch-simple"></v-switch>
                    <span>
                      Ik geef toestemming om benaderd te worden voor andere vrijwilligersmogelijkheden in de buurt, zoals de Natuurwerkdagen op 1 en 2 november
                    </span>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="switch-wrapper">
                    <v-switch :rules="nameRules" v-model="privacyAccepted" class="switch-simple"></v-switch>
                    <span>
                    Ik accepteer het
                        <a> 
                        privacybeleid
                        <v-dialog
                            v-model="showPrivacy"
                            activator="parent"
                            width="auto"
                        >
                            <v-card>
                            <v-card-text>
                                <h1>Privacy Statement</h1>
                                <p class="mb-4">Jouw privacy is voor ons erg belangrijk. Daarom handelen wij volgens de Algemene Verordening Gegevensbescherming (AVG) en gaan wij zorgvuldig om met de door jou ingevoerde gegevens.</p>
                                <v-expansion-panels>
                                <v-expansion-panel>
                                    <template v-slot:title>
                                    Meer
                                    </template>
                                    <template v-slot:text>
                                    <privacy-statement></privacy-statement>
                                    </template>
                                </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="primary" block @click="showPrivacy = false">Sluiten</v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-dialog>
                        </a>
                    </span>
                </div>
            </v-col>
        </v-row>
        <div class="mb-4">
          <v-alert
            color="success"
            title="Succes!"
            text="Uw gegevens zijn opgeslagen."
            v-model="showAlert"
            closable
          ></v-alert>
        </div>
        <div>
          <v-btn size="x-large" type="submit" :disabled="!isFormValid">Opslaan</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { db } from '../db';
import { server } from '../server';
import PrivacyStatement from './PrivacyStatement.vue';

export default {
  name: 'RegisterForm',
  props: {
    msg: String
  },
  components: {
    PrivacyStatement
  },

  data() {
    return {
      showAlert: false,
      showTerms: false,
      showPrivacy: false,
      privacyAccepted: false,
      person: {},
      isFormValid: false,
      nameRules: [
        v => !!v || 'Dit veld is verplicht',
      ],
      emailRules: [ 
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Voer een geldig e-mailadres in.'
      ]
    }
  },
  methods: {
    async submitForm() {
      try {
        var person = (({name, preposition, surname, email, gave_permission}) => ({name, preposition, surname, email, gave_permission}))(this.person);
        person.onServer = 0;
        this.addPersonLocally(person);
        this.sendPersonToServer(person);
      } catch (error) {
        console.error(`Failed to add ${this.person.name}: ${error}`);
      }
    },

    async sendPersonToServer(person) {
      server.sendPersonList([person]);
    },

    async addPersonLocally(person) {
      
        // Add the new person!
        const id = await db.persons.add(person);

        console.log(`Name ${this.person.name} locally added. Got id ${id}`);

        // Reset form:
        this.person = {};
        this.privacyAccepted = false;

        //Show alert:
        this.showAlert = true;
        window.setTimeout(() => { this.showAlert = false; }, 4000);
    },
  },
}
</script>
