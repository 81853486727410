<template>
  <v-app>
    <v-layout>
      <v-navigation-drawer>
        <admin-section />
      </v-navigation-drawer>
      <v-main>
        <v-container>
          <header>
            <div class="header-img"></div>
          </header>
          <div class="logo-wrapper">
            <img class="logo" alt="NMO logo" src="./assets/img/logo.png">
          </div>
          <RegisterForm />
          <PersonList></PersonList>
        </v-container>
      </v-main>
      <v-navigation-drawer location="right"></v-navigation-drawer>
    </v-layout>
  </v-app>
</template>

<script>
import RegisterForm from './components/Form.vue'
import PersonList from './components/PersonList.vue'
import AdminSection from './components/AdminSection.vue'

export default {
  name: 'App',
  components: {
    RegisterForm,
    PersonList,
    AdminSection
  }
}
</script>