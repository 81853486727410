<template>
    <v-btn @click="clear">Reset</v-btn>
    <div v-if="password">
        <v-btn @click="sendPeopleToServer">{{ personsNotOnServer ? personsNotOnServer.length : "..." }} not on server</v-btn>
        <v-expansion-panels>
            <v-expansion-panel>
                <template v-slot:title>
                    {{ allPersons ? allPersons.length : '' }} total
                </template>
                <template v-slot:text>
                    <v-btn @click="exportAll">Export</v-btn>
                    <!-- <ul>
                        <li v-for="person in allPersons" :key="person.id">{{ person.name }} {{ person.preposition ? person.preposition + " " : "" }} {{ person.surname }}, {{ person.email }}</li>
                    </ul> -->
                </template>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
    <div v-else>
        <v-checkbox v-model="check1" label="1"></v-checkbox>
        <v-checkbox v-model="check2" label="2"></v-checkbox>
        <v-checkbox v-model="check3" label="3"></v-checkbox>
        <v-checkbox v-model="check4" label="4"></v-checkbox>
        <v-checkbox v-model="check5" label="5"></v-checkbox>
        <v-checkbox v-model="check6" label="6"></v-checkbox>
    </div>
</template>

<script>
    import { liveQuery } from "dexie";
    import { useObservable } from "@vueuse/rxjs";
    import { db } from '../db';
    import { server } from "../server";
    import { excelParser } from "../excelParser";

    export default {

    name: 'AdminSection',
    data() {
        return {
            check1: false,
            check2: false,
            check3: false,
            check4: false,
            check5: false,
            check6: false,
        }
    },
    setup() {
        return {
            db,
            personsNotOnServer: useObservable(
                liveQuery(() => db.persons.where("onServer").equals(0).toArray())
            ),
            allPersons: useObservable(
                liveQuery(() => db.persons.toArray())
            ),
        };
    },
    methods: {
        async sendPeopleToServer() {
            server.sendPersonList(this.personsNotOnServer);
        },

        exportAll() {
            let yourDate = new Date()
            let date = yourDate.toISOString().split('T')[0];
            excelParser().exportDataFromJSON(this.allPersons, 'Oase-people-' + date, 'csv');
        },
        clear() {
            this.check1 = false;
            this.check2 = false;
            this.check3 = false;
            this.check4 = false;
            this.check5 = false;
            this.check6 = false;
        }
    },
    computed: {
        password() {
            return this.check1 && !this.check2 && !this.check3 && !this.check4 && this.check5 && this.check6;
        }
    }
}
</script>